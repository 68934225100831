@use '@angular/material' as mat;

$dark-primary-text: rgba(#000, 0.87);
$light-primary-text: white;
$primary-color-palette: (
  50: #f8fafc,
  100: #f1f5f9,
  200: #e2e8f0,
  300: #cbd5e1,
  400: #94a3b8,
  500: #64748b,
  600: #475569,
  700: #334155,
  800: #1e293b,
  900: #0f172a,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$typography: mat.m2-define-typography-config(
  $font-family: '"Noto Sans", "Noto Sans JP", "Helvetica Neue", sans-serif',
);
$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: mat.m2-define-palette($primary-color-palette),
      accent: mat.m2-define-palette($primary-color-palette),
      warn: mat.m2-define-palette(mat.$m2-red-palette),
    ),
    typography: $typography,
  )
);

@include mat.core();
@include mat.all-component-themes($theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Inter, 'Helvetica Neue', sans-serif;
}

button {
  appearance: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

button,
input,
a {
  outline-color: #000;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-circle {
  transform: rotate(15deg);
}

.circle {
  stroke-dasharray: 126;
  stroke-dashoffset: 126;
  animation: circle-draw 1.5s ease-in-out infinite;
}

@keyframes circle-draw {
  0% {
    stroke-dashoffset: 126;
  }

  50% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -126;
  }
}

.pre-line-tooltip {
  white-space: pre-line;
}
